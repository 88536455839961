<template>
  <v-app>
    <v-app-bar :dense="true" max-height="48px">
      <v-toolbar-title>
        <router-link to="/">
          <span class="text-h6">R. Lance Hunter</span>
        </router-link>
        <span class="text-uppercase hidden-sm-and-down"
          >&nbsp;&nbsp;Developer, Comic, Artist, Big Dude</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y transition="expand-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <span class="mr-2 hidden-sm-and-down color--black">Menu</span>
            <v-icon class="color--black">mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.link">
            <v-list-item-title>{{ item.title }}</v-list-item-title
            ><v-icon>{{ item.icon }}</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main transition="slide-x-transition">
      <router-view></router-view>
    </v-main>
    <v-footer padless>
      <v-card-text class="black--text">
        {{ new Date().getFullYear() }} —
        <strong>R. Lance Hunter</strong>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import Vuetify from "vuetify";
Vue.use(Vuetify);

export default {
  name: "App",
  data: () => ({
    item1: 1,
    menuItems: [
      {
        title: "Apps",
        icon: "mdi-code-tags",
        link: "apps",
      },
      {
        title: "Jokes",
        icon: "mdi-emoticon-excited-outline",
        link: "jokes",
      },
      {
        title: "Art",
        icon: "mdi-fire",
        link: "art",
      },
      {
        title: "Fitness",
        icon: "mdi-dumbbell",
        link: "fitness",
      },
    ],
  }),
  mounted() {
    // console.log("You can see me.");
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css");
* {
  font-family: "Roboto", sans-serif;
}
.text-uppercase {
  text-transform: uppercase;
}
.nowrap {
  white-space: nowrap;
}
</style>
