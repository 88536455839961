// import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#6244BB',
        secondary: '#94B0DA',
        accent: '#DCEDFF',
        //error: '#FFC348',
        //warning: '#FFD174',
        //info: '#B8A7EA',
        //success: '#866CD2',
        black: '#080705',
        background: '#FFFFFF'
      }
    }
  }
});