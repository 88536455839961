<template>
  <v-container class="mt-2">
    <v-row justify="center">
      <v-col>
        <v-img
          :src="require('../assets/lancehunter-logo-large.png')"
          class="hidden-sm-and-down"
          contain
          height="700"
        ></v-img>
        <v-img
          :src="require('../assets/lancehunter-logo-large.png')"
          class="hidden-md-and-up"
          contain
          height="400"
        ></v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <h1 class="text-h1 hidden-sm-and-down text-center black--text">Hello There</h1>
        <h2 class="text-h2 hidden-md-and-up text-center black--text">Hello There</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <p class="text-body-1 text-center black--text">I'm Lance. I do stuff.</p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <h3 class="text-h3 hidden-sm-and-down text-center black--text">Me In Other Places</h3>
        <h4 class="text-h4 hidden-md-and-up text-center black--text">Me In Other Places</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col v-for="(eco, i) in ecosystem" :key="i" class="text-center">
        <a :href="eco.href" class="text-subtitle-1 text-center" target="_blank">{{ eco.text }}</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    ecosystem: [
      {
        text: "GitHub",
        href: "https://github.com/LanceHunter"
      },
      {
        text: "StackOverflow",
        href: "https://stackoverflow.com/users/9704908"
      },
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/in/rlancehunter/"
      }
    ]
  })
};
</script>
