import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/homepageMain.vue';


Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/art',
            name: 'Art',
            component: () => import('./views/art.vue')
        },
        {
            path: '/jokes',
            name: 'Jokes',
            component: () => import('./views/jokes.vue')
        },
        {
            path: '/apps',
            name: 'Apps',
            component: () => import('./views/apps.vue')
        },
        {
            path: '/fitness',
            name: 'Fitness',
            component: () => import('./views/fitness.vue')
        }
    ]
});